export const PROJECT_NAME = 'Kushmanager POS';
export const BASE_URL = 'https://backend.kushmanager.com';
export const WEBSITE_URL = 'https://admin.kushmanager.com';
export const api_url = BASE_URL + '/api/v1/';
export const api_url_admin = BASE_URL + '/api/v1/dashboard/admin/';
export const api_url_admin_dashboard = BASE_URL + '/api/v1/dashboard/';
export const IMG_URL = '';
export const MAP_API_KEY = 'AIzaSyBx9ki-KTejw7NjvP_b85G9fvd42CrJteM';
export const export_url = BASE_URL + '/storage/';
export const example = BASE_URL + '/';
export const defaultCenter = { lat: 40.7127281, lng: -74.0060152 };

export const VAPID_KEY =
  'BPF2UBmQ7aS14ryUSFFG0PNNYBaLlCvmsd2RHG6ndaHE6aL-_blBxQoK0KAohAS8tkbbdV_6R5RPmUWkJCVZXCM';
export const API_KEY = 'AIzaSyA06MmOtNwSX6_F5dPiPAlmfYILr36_lig';
export const AUTH_DOMAIN = 'kushmanager-456fe.firebaseapp.com';
export const PROJECT_ID = 'kushmanager-456fe';
export const STORAGE_BUCKET = 'kushmanager-456fe.appspot.com';
export const MESSAGING_SENDER_ID = '844947372004';
export const APP_ID = '1:844947372004:web:32089cefb2d11a4b4f79f1';
export const MEASUREMENT_ID = 'G-H4SSFR4756';

export const RECAPTCHASITEKEY = '6LcmKQ0qAAAAAN-5VfGLbLfgv-P9pCyadxzSoMBt';

export const DEMO_SELLER = 107; // seller_id
export const DEMO_SHOP = 501; // seller_id
export const DEMO_DELIVERYMAN = 106; // deliveryman_id
export const DEMO_MANEGER = 114; // maneger_id
export const DEMO_MODERATOR = 297; // moderator_id
export const DEMO_ADMIN = 501; // administrator_id
export const DEMO_WAITER = 108; // waiter_id

export const SUPPORTED_FORMATS = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'image/svg+xml',
  'image/svg',
];
